'use client';
import React from 'react';
import { Button } from '@/components/atom/button';
import { usePathname, useRouter } from 'next/navigation';

const TemporaryBanner = () => {
  const router = useRouter();
  const pathname = usePathname();

  return (
    <div className="md:max-w-[1200px] flex justify-between items-center w-full">
      <div className="flex-col gap-0 hidden md:flex">
        <p className="text-white text-lg font-bold">
          Find interesting communities and people on Fusion.
        </p>
        <p className="text-white text-xs">Connect and Explore.</p>
      </div>

      <div className="flex gap-3 items-center justify-between md:justify-start w-full md:w-fit px-4 md:px-0">
        <Button
          variant="outline"
          className="w-full md:w-fit text-white bg-transparent border border-white py-1 font-semibold"
          onClick={() => router.push('/signin')}
        >
          Log in
        </Button>

        <Button
          className="w-full md:w-fit text-black bg-white font-semi-bold hover:bg-purple-brand5 hover:text-white"
          onClick={() => router.push('/signup')}
        >
          Sign up
        </Button>
      </div>
    </div>
  );
};

export default TemporaryBanner;
