import { getPosts } from '@/services/posts.service';
import { PostParamsType } from '@/types';
import { useInfiniteQuery } from '@tanstack/react-query';

const useGetAllPosts = ({ PageSize = 5, PageNumber, ...rest }: PostParamsType) => {
  const { data, ...other } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['GetAllPosts', { PageSize, ...rest }],
    queryFn: ({ pageParam }) => getPosts({ PageSize, PageNumber: pageParam, ...rest }),
    getNextPageParam: (lastPage: any, pages) =>
      lastPage?.hasNextPage && pages ? pages?.length + 1 : undefined,
  });

  const payload = data?.pages?.reduce((acc, page) => [...acc, ...page.data], []);

  return { ...other, data: payload, total: data?.pages[0]?.totalCount ?? '0' };
};

export default useGetAllPosts;
