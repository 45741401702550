'use client';

import React from 'react';
import CommunityList from './CommunityList';
import useGetCommunitySidePanelQuery from '@/hooks/react-query/communities/queries/useGetCommunitySidePanelQuery';
import CommunitySummaryLoader from './CommunitySummaryLoader';

const CommunitySummary: React.FC = () => {
  const { data, isLoading } = useGetCommunitySidePanelQuery({ Search: '' });

  if (isLoading)
    return (
      <div className="w-full flex flex-col gap-0 pb-0">
        <CommunitySummaryLoader />
      </div>
    );

  return (
    <div className="rounded-lg bg-white flex flex-col gap-4 w-full">
      {data
        ?.filter((b) => b.communities.length > 0)
        .map((v) => <CommunityList key={v.title} title={v.title} communities={v.communities} />)}
    </div>
  );
};

export default CommunitySummary;
