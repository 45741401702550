'use client';

import React from 'react';
import { Button } from '@/components/atom/button';
import { usePathname, useRouter } from 'next/navigation';
import { MODAL_PARAMS } from '@/constants';

const CreatePostBanner = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  const router = useRouter();
  const pathname = usePathname();

  return (
    <div className="w-full flex flex-col gap-4 p-4 bg-pink-brand-muted rounded-lg">
      <Button
        className="w-full bg-white felx justify-start rounded-sm p-4 h-[72px] flex items-center gap-4 cursor-pointer"
        onClick={() => {
          if (isAuthenticated) {
            router.replace(`${pathname}?${MODAL_PARAMS.type}=${MODAL_PARAMS.newPost}`);
          } else {
            router.replace(`${pathname}?ref=signin`);
          }
        }}
        variant={`ghost`}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M25.8 5H12.5334C6.78337 5 3.33337 8.43333 3.33337 14.2V27.45C3.33337 33.2333 6.78337 36.6667 12.5334 36.6667H25.7834C31.55 36.6667 34.9834 33.2333 34.9834 27.4667V14.2C35 8.43333 31.55 5 25.8 5Z"
            fill="#601E8A"
          />
          <path
            d="M35.0333 4.96745C32.05 1.96745 29.1333 1.90078 26.0667 4.96745L24.1833 6.83412C24.0167 7.00078 23.9667 7.23412 24.0333 7.45078C25.2 11.5341 28.4667 14.8008 32.55 15.9675C32.6 15.9841 32.6833 15.9841 32.7333 15.9841C32.9 15.9841 33.0667 15.9175 33.1833 15.8008L35.0333 13.9341C36.55 12.4175 37.3 10.9675 37.3 9.48412C37.3 7.98412 36.55 6.50079 35.0333 4.96745Z"
            fill="#FF951C"
          />
          <path
            d="M29.7667 17.3661C29.3167 17.1494 28.8833 16.9327 28.4833 16.6827C28.15 16.4827 27.8167 16.2661 27.5 16.0327C27.2333 15.8661 26.9333 15.6161 26.6333 15.3661C26.6 15.3494 26.5 15.2661 26.3667 15.1327C25.85 14.7161 25.3 14.1494 24.7833 13.5327C24.75 13.4994 24.65 13.3994 24.5667 13.2494C24.4 13.0661 24.15 12.7494 23.9333 12.3994C23.75 12.1661 23.5333 11.8327 23.3333 11.4827C23.0833 11.0661 22.8667 10.6494 22.6667 10.2161C22.45 9.74941 22.2833 9.31608 22.1333 8.89941L13.1667 17.8661C12.5833 18.4494 12.0167 19.5494 11.9 20.3661L11.1833 25.3327C11.0333 26.3827 11.3167 27.3661 11.9667 28.0161C12.5167 28.5661 13.2667 28.8494 14.1 28.8494C14.2833 28.8494 14.4667 28.8327 14.65 28.8161L19.6 28.1161C20.4167 27.9994 21.5167 27.4494 22.1 26.8494L31.0667 17.8827C30.65 17.7494 30.2333 17.5661 29.7667 17.3661Z"
            fill="#601E8A"
          />
        </svg>

        <h6 className="text-gray-brand9 font-medium text-base md:text-lg">
          Share your thoughts...
        </h6>
      </Button>

      <div className="flex justify-between w-full items-center">
        <div className="flex gap-5 items-center">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
              fill="#F07306"
            />
            <path
              d="M5.93944 8.89232C6.67584 8.89232 7.27281 8.29535 7.27281 7.55895C7.27281 6.82255 6.67584 6.22559 5.93944 6.22559C5.20305 6.22559 4.60608 6.82255 4.60608 7.55895C4.60608 8.29535 5.20305 8.89232 5.93944 8.89232Z"
              fill="#F3E9FE"
            />
            <path
              d="M12.0778 8.89232C12.8141 8.89232 13.4111 8.29535 13.4111 7.55895C13.4111 6.82255 12.8141 6.22559 12.0778 6.22559C11.3414 6.22559 10.7444 6.82255 10.7444 7.55895C10.7444 8.29535 11.3414 8.89232 12.0778 8.89232Z"
              fill="#F3E9FE"
            />
            <path
              d="M12.372 11.2118C10.212 12.7318 7.81191 12.7051 5.65186 11.2118C5.38518 11.0251 5.17184 11.3451 5.33185 11.6384C5.99853 12.7051 7.30523 13.6918 9.01194 13.6918C10.7186 13.6918 12.0253 12.7318 12.692 11.6384C12.852 11.3451 12.6387 11.0251 12.372 11.2118Z"
              fill="#F3E9FE"
            />
          </svg>

          <Button
            variant={'ghost'}
            className="w-fit bg-none p-0 hover:bg-pink-brand-muted"
            onClick={() => {
              if (isAuthenticated) {
                router.replace(`${pathname}?${MODAL_PARAMS.type}=${MODAL_PARAMS.newPost}`);
              } else {
                router.replace(`${pathname}?ref=signin`);
              }
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.475 0.833008H15.025C14.3 0.833008 13.7667 1.13301 13.525 1.66634C13.3917 1.90801 13.3334 2.19134 13.3334 2.52467V4.97467C13.3334 6.03301 13.9667 6.66634 15.025 6.66634H17.475C17.8084 6.66634 18.0917 6.60801 18.3334 6.47467C18.8667 6.23301 19.1667 5.69967 19.1667 4.97467V2.52467C19.1667 1.46634 18.5334 0.833008 17.475 0.833008ZM18.2584 4.10801C18.175 4.19134 18.05 4.24967 17.9167 4.25801H16.7417V4.68301L16.75 5.41634C16.7417 5.55801 16.6917 5.67467 16.5917 5.77467C16.5084 5.85801 16.3834 5.91634 16.25 5.91634C15.975 5.91634 15.75 5.69134 15.75 5.41634V4.24967L14.5834 4.25801C14.3084 4.25801 14.0834 4.02467 14.0834 3.74967C14.0834 3.47467 14.3084 3.24967 14.5834 3.24967L15.3167 3.25801H15.75V2.09134C15.75 1.81634 15.975 1.58301 16.25 1.58301C16.525 1.58301 16.75 1.81634 16.75 2.09134L16.7417 2.68301V3.24967H17.9167C18.1917 3.24967 18.4167 3.47467 18.4167 3.74967C18.4084 3.89134 18.35 4.00801 18.2584 4.10801Z"
                fill="#601E8A"
              />
              <path
                d="M7.49993 8.65026C8.5953 8.65026 9.48327 7.76229 9.48327 6.66693C9.48327 5.57156 8.5953 4.68359 7.49993 4.68359C6.40457 4.68359 5.5166 5.57156 5.5166 6.66693C5.5166 7.76229 6.40457 8.65026 7.49993 8.65026Z"
                fill="#601E8A"
              />
              <path
                d="M17.475 6.66699H17.0833V10.5087L16.975 10.417C16.325 9.85866 15.275 9.85866 14.625 10.417L11.1583 13.392C10.5083 13.9503 9.45829 13.9503 8.80829 13.392L8.52496 13.1587C7.93329 12.642 6.99163 12.592 6.32496 13.042L3.20829 15.1337C3.02496 14.667 2.91663 14.1253 2.91663 13.492V6.50866C2.91663 4.15866 4.15829 2.91699 6.50829 2.91699H13.3333V2.52533C13.3333 2.19199 13.3916 1.90866 13.525 1.66699H6.50829C3.47496 1.66699 1.66663 3.47533 1.66663 6.50866V13.492C1.66663 14.4003 1.82496 15.192 2.13329 15.8587C2.84996 17.442 4.38329 18.3337 6.50829 18.3337H13.4916C16.525 18.3337 18.3333 16.5253 18.3333 13.492V6.47533C18.0916 6.60866 17.8083 6.66699 17.475 6.66699Z"
                fill="#601E8A"
              />
            </svg>
          </Button>

          <Button
            variant={'ghost'}
            className="w-fit bg-none p-0 hover:bg-pink-brand-muted"
            onClick={() => {
              if (isAuthenticated) {
                router.replace(`${pathname}?${MODAL_PARAMS.type}=${MODAL_PARAMS.newPost}`);
              } else {
                router.replace(`${pathname}?ref=signin`);
              }
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12.275 1.66699H7.72498V5.30033H12.275V1.66699Z" fill="#B12924" />
              <path
                d="M13.525 1.66699V5.30033H18.225C17.8 3.00866 16.1084 1.67533 13.525 1.66699Z"
                fill="#B12924"
              />
              <path
                d="M1.66663 6.55078V13.4924C1.66663 16.5258 3.47496 18.3341 6.50829 18.3341H13.4916C16.525 18.3341 18.3333 16.5258 18.3333 13.4924V6.55078H1.66663ZM12.0333 13.4841L10.3 14.4841C9.93329 14.6924 9.57496 14.8008 9.24163 14.8008C8.99163 14.8008 8.76663 14.7424 8.55829 14.6258C8.07496 14.3508 7.80829 13.7841 7.80829 13.0508V11.0508C7.80829 10.3174 8.07496 9.75078 8.55829 9.47578C9.04163 9.19245 9.65829 9.24245 10.3 9.61745L12.0333 10.6174C12.675 10.9841 13.025 11.5008 13.025 12.0591C13.025 12.6174 12.6666 13.1091 12.0333 13.4841Z"
                fill="#B12924"
              />
              <path
                d="M6.47502 1.66699C3.89169 1.67533 2.20002 3.00866 1.77502 5.30033H6.47502V1.66699Z"
                fill="#B12924"
              />
            </svg>
          </Button>
        </div>

        <Button className="flex items-center justify-center bg-gray-brand2 px-4 py-2.5 rounded-sm gap-2 hover:bg-gray-brand2">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.16665 5.2668L13.2417 2.90846C16.4167 1.85013 18.1417 3.58346 17.0917 6.75846L14.7333 13.8335C13.15 18.5918 10.55 18.5918 8.96665 13.8335L8.26665 11.7335L6.16665 11.0335C1.40832 9.45013 1.40832 6.85846 6.16665 5.2668Z"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.42505 11.3755L11.4084 8.38379"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span className="text-white font-medium text-sm">Post</span>
        </Button>
      </div>
    </div>
  );
};

export default CreatePostBanner;
